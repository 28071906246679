.title-container {
    min-height: 100vh;
    width: 100%;
    background-color: #0f0f0f;
    color: #33f32c;
    padding: 0 0 0 7vw;
}

.particles {
    height: 100%;
    width: 100%;
    position: absolute !important;
    pointer-events: none;
}

.title {
    padding: 35vh 0;
}

.title-header {
    font-size: 3em;
}

.scroll-down {
    display: inline;
}

.title a:link {
    color: #ffffff;
    text-decoration: none;
}

.title a:visited {
    color: #ffffff;
    text-decoration: none;
}

.title a:hover {
    color: dodgerblue;
    text-decoration: none;
    transition: 0.1s;
}
