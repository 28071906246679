.about-container {
    min-height: 100vh;
    width: 100%;
    background-color: #0f0f0f;
    color: #33f32c;
    padding: 0 0 50px 7vw;
}

.about-title {
    padding: 10vh 0 0 0;
}

.about-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    margin: 3% 3% 0 0;
}

.about-item-me {
    width: 40vh;
    margin: 0 3% 3% 0;
}

.me-paragraph {
    font-size: larger;
}

.about-item-contacts {
    margin: 0 0 3% 0;
    padding: 3vh;
    background-color: #343A40;
    border: 1px solid #33f32c;
    border-radius: 4px;
}

.contacts-header {
    margin: 0 0 5vh 0;
}
