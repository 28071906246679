.App {
  font-family: 'silkscreennormal', sans-serif;
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-link {
  color: #1fa11a;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .navbar-container {
    background-color: #000022;
    box-shadow: 0px 2px 5px 3px rgb(0, 0, 0, 0.3);
}

.navbar-container a {
    color: #ffffff;
}

.navbar-container a:hover {
    color: dodgerblue;
    transition: 0.3s;
}

#Title, #Experience, #Projects, #About {
  width: 100%;
} */


a:link {
    color: #33f32c;
    text-decoration: none;
}

a:visited {
    color: #33f32c;
    text-decoration: none;
}

a:hover {
    color: #1fa11a !important;
    text-decoration: none;
    transition: 0.1s;
}
