.projects-container {
    min-height: 100vh;
    width: 100%;
    background-color: #0f0f0f;
    color: #33f32c;
    padding: 0 0 50px 7vw;
}

.projects-title {
    padding: 10vh 0 0 0;
}

.projects-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    margin: 3% 3% 0 0;
}

.projects-card {
    margin: 0 50px 50px 0;
}
